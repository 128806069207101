var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-transfer',{attrs:{"data-source":_vm.dataSubmarterial,"target-keys":_vm.right.selectedKeys,"rowKey":function (record) { return record.id; },"show-search":_vm.showSearch,"filter-option":function (inputValue, item) { return item.materialName.indexOf(inputValue) !== -1; },"show-select-all":false,"titles":['可选择图文档', '已选择图文档']},on:{"change":_vm.whenTransferData,"search":_vm.whenSearch},scopedSlots:_vm._u([{key:"children",fn:function(ref){
      var ref_props = ref.props;
      var direction = ref_props.direction;
      var selectedKeys = ref_props.selectedKeys;
      var ref_on = ref.on;
      var itemSelectAll = ref_on.itemSelectAll;
      var itemSelect = ref_on.itemSelect;
return [(direction === 'left')?_c('a-table',{attrs:{"pagination":_vm.left.pagination,"columns":_vm.left.columns,"data-source":_vm.left.dataSource,"rowKey":function (record) { return record.id; },"row-selection":_vm.getSelection(direction, selectedKeys, itemSelect, itemSelectAll),"size":"small","custom-row":function (ref) {
            var id = ref.id;

            return ({
            on: {
              click: function () {
                itemSelect(id, !selectedKeys.includes(id));
              },
            },
          });
      },"loading":_vm.left.loading},on:{"change":_vm.changeLeftPage}}):_vm._e(),(direction === 'right')?_c('a-table',{attrs:{"pagination":_vm.right.pagination,"columns":_vm.right.columns,"data-source":_vm.right.dataSource,"rowKey":function (record) { return record.id; },"size":"small","custom-row":function (ref) {
                var id = ref.id;

                return ({
            on: {
              click: function () {
                itemSelect(id, !selectedKeys.includes(id));
              },
            },
          });
      },"row-selection":_vm.getSelection(direction, selectedKeys, itemSelect, itemSelectAll),"loading":_vm.right.loading},on:{"change":_vm.changeRightPage}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }