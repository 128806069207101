<template>
  <a-form-model
    class="details-form"
    :model="editForm"
    ref="editForm"
    :rules="rules"
    οnsubmit="return false"
  >
    <a-row>
      <a-col :span="12">
        <a-form-model-item label="版本：">
          <a-select
            :value="editForm.id"
            :disabled="!isEdit"
            @change="whenChangeItem"
          >
            <a-select-option v-for="item in dicts.versions" :key="item.id">
              {{ item.name }}
            </a-select-option>
            <a-select-option key="new" value="new">新增版本</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="12" v-if="editable">
        <a @click="whenEditRow" v-show="!isEdit">编辑</a>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12">
        <a-form-model-item label="物料名称：" ref="name" prop="name">
          <a-input
            v-model="editForm.name"
            :rules="{
              required: true,
              message: '请输入名称',
              trigger: 'blur',
            }"
            :disabled="!isEdit"
            @pressEnter.stop="cancelEnter"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item label="物料编码：" ref="fullCode" prop="fullCode">
          <a-input v-model="editForm.fullCode" :disabled="true" />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12">
        <a-form-model-item label="材质：" ref="texture" prop="texture">
          <a-input v-model="editForm.texture" :disabled="!isEdit" @pressEnter.stop="cancelEnter"/>
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item label="规格型号：" ref="specification" prop="specification">
          <a-input v-model="editForm.specification" :disabled="!isEdit" @pressEnter.stop="cancelEnter"/>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12">
        <a-form-model-item label="主工艺：" ref="mainProcess" prop="mainProcess">
          <a-input v-model="editForm.mainProcess" :disabled="!isEdit" @pressEnter.stop="cancelEnter"/>
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item label="分类：" ref="classificationID" prop="classificationID">
          <a-select
            v-model="editForm.classificationID"
            :disabled="true"
            :showSearch="true"
            @search="whenSearchClassification"
          >
            <a-select-option
              v-for="item in dicts.classifications"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12">
        <a-form-model-item label="单位：" ref="unitID" prop="unitID">
          <a-select v-model="editForm.unitID" :disabled="!isEdit">
            <a-select-option v-for="item in dicts.units" :key="item.id">
              {{ item.codeName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item label="来源" ref="source" prop="source">
          <a-select v-model="editForm.source" :disabled="!isEdit">
            <a-select-option v-for="item in dicts.sources" :key="item.id">
              {{ item.codeName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12">
        <a-form-model-item label="描述：" ref="description" prop="description">
          <a-input
            type="textarea"
            v-model="editForm.description"
            :disabled="!isEdit"
            @pressEnter.stop="cancelEnter"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row v-if="customAttrLoading">
      <a-col
        :span="12"
        v-for="(attr, index) in editForm.customeProperties"
        :key="index"
      >
         <a-form-model-item
                :label="attr.name"
                :rules="{
                  pattern:/^\d+(\.{0,1}\d+){0,11}$/,
                  required: attr.required,
                  message: '请输入大于0的数值',
                  trigger: 'blur',
                }"
                :prop="'customeProperties.' + index + '.value'"
                v-if="attr.codeType == 'Num'"
              >
              <a-input
            v-model="attr.value"
            style="width: 100%"
            :disabled="!isEdit"
            @pressEnter.stop="cancelEnter"
          />
        </a-form-model-item>
        <a-form-model-item
          :label="attr.name"
          :rules="{
            required: attr.required,
            message: '请输入内容',
            trigger: ['change','blur'],
          }"
          :prop="'customeProperties.' + index + '.value'"
          v-else
        >
          <a-input
            v-model="attr.value"
            v-if="attr.codeType == 'Text'"
            :disabled="!isEdit"
            @pressEnter.stop="cancelEnter"
          />
          <a-textarea
            v-model="attr.value"
            v-if="attr.codeType == 'LongText'"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            :disabled="!isEdit"
            @pressEnter.stop="cancelEnter"
          />
          <a-select
            v-model="attr.value"
            v-if="attr.codeType == 'Select'"
            style="width: 100%"
            :disabled="!isEdit"
          >
            <a-select-option
              v-for="(option, idx) in attr.unit.unit"
              :key="idx"
              :value="option"
              >{{ option }}</a-select-option
            >
          </a-select>
          <a-date-picker
            v-model="attr.value"
            v-if="attr.codeType == 'Date'"
            :format="dateFormat"
            placeholder="日期"
            style="width: 100%"
            :valueFormat="dateFormat"
            :disabled="!isEdit"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
    <div style="text-align: right" v-show="isEdit">
      <a-button
        type="primary"
        :loading="loading"
        @click="handleSubmit"
        html-type="submit"
      >
        提交
      </a-button>
    </div>
  </a-form-model>
</template>

<script>
import {
  getMaterial,
  updateMaterial,
  getDropdownList as versionDropdownList,
} from "@/api/material";
import {
  getDropdownList,
  getUnitDownListDown,
  getMaterialClassification,
  getSourceDownList,
} from "@/api/materialclassification";
export default {
  name: "MaterialDetailBasicEdit",
  props: {
    form: { type: Object, default: () => {} },
    editable: { type: Boolean, default: () => false },
    isEdit: { type: Boolean, default: () => false },
    itemId: { type: String, default: () => "" },
  },
  created() {
    this.loadDict();
  },
  data: () => {
    return {
      dateFormat: "YYYY-MM-DD",
      editForm: {},
      loading: false,
      dicts: {
        classifications: [],
        versions: [],
        units: [],
        sources: [],
      },
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 126,
            message: "名称长度应在1-126字符之间",
            whitespace: true,
            trigger: "blur",
          },
        ],
        fullCode: [{ required: true, message: "请输入内容", trigger: "blur" }],
        classificationID: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        unitID: [{ required: false, message: "请输入内容", trigger: "blur" }],
        specification: [
          { required: false, message: "请输入内容", trigger: "blur" },
        ],
        mainProcess: [
          { required: false, message: "请输入内容", trigger: "blur" },
        ],
        texture: [{ required: false, message: "请输入内容", trigger: "blur" }],
        description: [
          { required: false, message: "请输入内容", trigger: "blur" },
        ],
        source: [
          { required: true, message: "请选择内容", trigger: "blur" },
        ],
      },
      customAttrLoading: false,
    };
  },
  methods: {
    whenChangeItem(e) {
      if (e === "new") {
        const f = { ...this.editForm };
        f.isNew = true;
        let _this=this;
        updateMaterial(f).then((res) => {
          _this.loadVersionList();
          _this.editForm=res;
          _this.getMaterialClassification(res.classificationID);
          _this.$emit('changeVersion',res.id);
        });
      } else {
        this.getMaterial(e);
        this.$emit('changeVersion',e);
      }
    },
    whenEditRow() {
      this.$emit("edit", true);
    },
    whenSearchClassification(e) {
      getDropdownList({ filter: e })
        .then((result) => {
          this.dicts.classifications = result.items;
        })
        .catch(function () {});
    },
    loadDict() {
      getDropdownList()
        .then((result) => {
          this.dicts.classifications = result.items;
        })
        .catch(function () {});

      getUnitDownListDown("HS_MaterialUnits")
        .then((result) => {
          this.dicts.units = result;
        })
        .catch(function () {});

      //来源下拉
      getSourceDownList("HS_Source")
        .then((result) => {
          this.dicts.sources = result;
        })
        .catch();
    },
    loadVersionList() {
      versionDropdownList({
        classificationID: this.editForm.classificationID,
        fullCode: this.editForm.fullCode,
      })
        .then((result) => {
          this.dicts.versions = result;
        })
        .catch(function () {});
    },
    handleSubmit() {
      let _this = this;
      this.$refs.editForm.validate((e) => {
        if (e) {
          this.loading = true;
          updateMaterial(_this.editForm)
            .then(() => {
              this.loading = false;
              this.$message.success("修改成功");
              this.$emit("submit");
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onCellChange(key, dataIndex, value) {
      console.log("---", key, dataIndex, value);
      const dataSource = [...this.dataSource];
      const target = dataSource.find((item) => item.key === key);
      if (target) {
        target[dataIndex] = value;
        this.dataSource = dataSource;
      }
    },
    getMaterial(id) {
      let _this=this;
      getMaterial(id).then((res) => {
        _this.editForm = res;
        _this.getMaterialClassification(res.classificationID);
      });
    },
    getMaterialClassification(id) {
      if (id == undefined) return;
      let _this = this;
      getMaterialClassification(id).then((res) => {
        for (let a of res.attributes) {
           //默认属性
          if (a.classify == 0) {
            switch (a.name) {
              case "规格型号":
                if (a.required) {
                  _this.rules.specification = [
                    { required: true, message: "请输入内容", trigger: "blur" },
                  ];
                }
                break;
              case "主工艺":
                if (a.required) {
                  _this.rules.mainProcess = [
                    { required: true, message: "请输入内容", trigger: "blur" },
                  ];
                }
                break;
              case "材质":
                if (a.required) {
                  _this.rules.texture = [
                    { required: true, message: "请输入内容", trigger: "blur" },
                  ];
                }
                break;
              case "描述":
                if (a.required) {
                  _this.rules.description = [
                    { required: true, message: "请输入内容", trigger: "blur" },
                  ];
                }
                break;
              case "单位":
                if (a.required) {
                  _this.rules.unitID = [
                    { required: true, message: "请输入内容", trigger: "blur" },
                  ];
                }
                break;
              default:
                break;
            }
          }
        }
        _this.customAttrLoading = true;
        });
    },
    //阻止enter提交
    cancelEnter(e){
      e.preventDefault();
    },
    onValuesChange(e){
      this.$forceUpdate();
    }
  },
  watch: {
    form: {
      handler() {
        this.editForm = { ...this.form } || {};
        this.loadVersionList();
        this.getMaterialClassification(this.form.classificationID);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style></style>
