/* eslint-disable no-unused-vars */
import moment from "moment";
export default [
  {
    title: "序号",
    dataIndex: "index",
  },
  {
    title: "文档名称",
    dataIndex: "docName",
    width: 250,
    ellipsis: true,
  },
  {
    title: "文档编码",
    dataIndex: "docCode",
  },
  {
    title: "文档格式",
    dataIndex: "docFormat",
  },
  {
    title: "版本",
    dataIndex: "docVersion",
    scopedSlots: { customRender: "version" },
  },
  {
    title: "上传人",
    dataIndex: "creatorName",
  },
  {
    title: "上传时间",
    dataIndex: "creationTime",
    customRender: (text, record, index) =>
      moment(record.creationTime).format("YYYY-MM-DD"),
  },
  {
    title: "大小",
    dataIndex: "docSize",
  },
  {
    title: "操作",
    scopedSlots: { customRender: "action" },
    width: 150,
    align: "center",
  },
];
