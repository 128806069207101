<template>
  <div>
    <a-transfer
      :data-source="dataSubmarterial"
      :target-keys="right.selectedKeys"
      :rowKey="(record) => record.id"
      :show-search="showSearch"
      :filter-option="
        (inputValue, item) => item.materialName.indexOf(inputValue) !== -1
      "
      :show-select-all="false"
      :titles="['可选择图文档', '已选择图文档']"
      @change="whenTransferData"
      @search="whenSearch"
    >
      <template
        slot="children"
        slot-scope="{
          props: { direction, selectedKeys },
          on: { itemSelectAll, itemSelect },
        }"
      >
        <a-table
          v-if="direction === 'left'"
          :pagination="left.pagination"
          :columns="left.columns"
          :data-source="left.dataSource"
          :rowKey="(record) => record.id"
          :row-selection="
            getSelection(direction, selectedKeys, itemSelect, itemSelectAll)
          "
          size="small"
          :custom-row="
            ({ id }) => ({
              on: {
                click: () => {
                  itemSelect(id, !selectedKeys.includes(id));
                },
              },
            })
          "
          :loading="left.loading"
          @change="changeLeftPage"
        />
        <a-table
          v-if="direction === 'right'"
          :pagination="right.pagination"
          :columns="right.columns"
          :data-source="right.dataSource"
          :rowKey="(record) => record.id"
          size="small"
          :custom-row="
            ({ id }) => ({
              on: {
                click: () => {
                  itemSelect(id, !selectedKeys.includes(id));
                },
              },
            })
          "
          :row-selection="
            getSelection(direction, selectedKeys, itemSelect, itemSelectAll)
          "
          :loading="right.loading"
          @change="changeRightPage"
        />
      </template>
    </a-transfer>
  </div>
</template>

<script>
import difference from "lodash/difference";
import { query, getManyItems } from "@/api/figureDocument.js";

const columns = [
  {
    dataIndex: "docName",
    title: "文档名称",
    ellipsis: true,
    width: 100,
  },
  {
    dataIndex: "docCode",
    title: "文档编码",
    ellipsis: true,
    width: 100,
  },
  {
    dataIndex: "docFormat",
    title: "文档类型",
    ellipsis: true,
    width: 100,
  },
];

export default {
  name: "MaterialBindAttachment",
  props: {
    existsData: { type: Array, default: () => [] },
  },
  data() {
    return {
      dataSubmarterial: [],
      targetKeys: [],
      showSearch: true,
      left: {
        columns: columns,
        pagination: {
          pageSize: 10,
          total: 0,
          current: 1,
        },
        filter: undefined,
        dataSource: [],
        selectedKeys: [],
        loading: false,
      },
      right: {
        columns: columns,
        pagination: {
          pageSize: 10,
          total: 0,
          current: 1,
        },
        filter: undefined,
        dataSource: [],
        selectedKeys: [],
        loading: false,
      },
    };
  },
  created() {
    console.info(this.existsData)
   
  },
  methods: {
    whenSearch(direction, value) {
      if (direction === "left") {
        this.left.pagination.current = 1;
        this.left.filter = value;
        this.delayLoad(this.left, () => this.loadLeftDataAsync());
      } else {
        this.right.pagination.current = 1;
        this.right.filter = value;
        this.delayLoad(this.right, () => this.loadRightDataAsync());
      }
    },
    delayLoad(direction, action) {
      const timer = setTimeout(() => {
        if (direction.timer !== timer) return;
        action();
      }, 500);
      direction.timer = timer;
    },
    loadLeftDataAsync() {
      this.left.loading = true;
      const params = {
        key: this.left.filter,
        skipCount: this.leftSkip,
        maxResultCount: this.left.pagination.pageSize,
        excludeItemIdList: this.right.selectedKeys,
      };
      query(params).then((res) => {
        this.left.loading = false;
        this.left.dataSource = res.items;
        this.left.pagination.total = res.totalCount;
      });
    },
    loadRightDataAsync() {
      this.right.loading = true;
      const params = {
        itemIdList: this.right.selectedKeys,
        filter: this.right.filter,
        skipCount: this.rightSkip,
        maxResultCount: this.right.pagination.pageSize,
      };
      getManyItems(params).then((res) => {
        this.right.loading = false;
        this.right.dataSource = res.items;
        this.right.total = res.totalCount;
      });
    },
    whenTransferData(nextTargetKeys) {
      this.right.selectedKeys = nextTargetKeys;
      this.loadLeftDataAsync();
    },
    changeLeftPage(pagination) {
      const pager = { ...this.left.pagination };
      pager.current = pagination ? pagination.current : pager.current;
      this.left.pagination = pager;
      this.loadLeftDataAsync();
    },
    changeRightPage(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination ? pagination.current : pager.current;
      this.right.pagination = pager;
      this.loadRightDataAsync();
    },
    getSelection(direction, selectedKeys, itemSelect, itemSelectAll) {
      return {
        getCheckboxProps: (item) => {
          // this 判断修改 props无效
          return { props: { disabled: item.disabled } };
        },
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ id }) => id);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ id }, selected) {
          itemSelect(id, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },
  },
  computed: {
    leftSkip() {
      return (this.left.pagination.current - 1) * this.left.pagination.pageSize;
    },
    rightSkip() {
      return (
        (this.right.pagination.current - 1) * this.right.pagination.pageSize
      );
    },
  },
  watch: {
    existsData: {
      handler() {
        this.right.selectedKeys.splice(0);
        if (this.existsData && this.existsData.length) {
          this.right.selectedKeys.push(...this.existsData);
          this.left.selectedKeys.push(...this.existsData);
          this.loadLeftDataAsync();
        }
      },
      deep: true,
      immediate: true,
    },
    "right.selectedKeys": {
      handler() {
        this.loadRightDataAsync();
      },
      deep: true,
      immediate: true,
    },
    selectedData:{
      handler(e){
        console.info(e)
        this.loadLeftDataAsync();
      },
      deep:true,
      immediate:true,
    }
  },
};
</script>
