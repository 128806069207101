<template>
  <div class="details">
    <a-page-header title="物料详情" :ghost="false">
      <template #extra>
        <a-button type="primary" @click="backTo">返回</a-button>
      </template>
    </a-page-header>
    <div class="details-info bgStyle">
      <h3>物料详情</h3>
      <div class="DI-edit">
        <BasicEdit
          :editable="editable"
          :form="formData"
          :itemId="itemId"
          :isEdit="isEditing"
          @submit="afterSaved"
          @edit="whenEditRow"
          @changeVersion="whenVersionChange"
        />
      </div>
    </div>
    <AttachmentList :itemId="materialItemId" :isEdit="editable" />
  </div>
</template>

<script>
import { getMaterial } from "@/api/material";
import BasicEdit from "./components/DetailBasicEdit.vue";
import AttachmentList from "./attachments/List.vue";
export default {
  components: {
    BasicEdit,
    AttachmentList,
  },
  props: {
    itemId: { type: String, default: () => "" },
    isEdit: { type: Boolean, default: () => false },
  },
  data() {
    return {
      formData: {},
      editable: this.isEdit,
      isEditing: false,
      materialItemId:this.itemId,
    };
  },

  created() {
    this.loadDataAsync();
  },
  methods: {
    loadDataAsync() {
      if (!this.itemId) return;
      const _this = this;
      getMaterial(this.itemId)
        .then(function (result) {
          _this.formData = result;
        })
        .catch(function () {});
    },
    handleOk(e) {
      console.log(e);
      this.visibleNew = false;
    },
    ApOk(e) {
      console.log(e);
      this.visibleAppendixModal = false;
    },
    afterSaved() {
      this.loadDataAsync();
    },
    whenEditRow(e) {
      this.isEditing = e;
    },

    backTo(){
      this.$router.push({
        name: 'MaterialManagementIndex'
      })
    },
    whenVersionChange(id){
      this.materialItemId=id;
    }
  },
  watch: {
    isEdit: {
      handler() {
        this.editable = this.isEdit;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.details h3 {
  margin-bottom: 20px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 10px;
}

.details-form ::v-deep.ant-form-item-label {
  display: inline-block;
  width: 165px;
  margin-right: 15px;
  text-align: left;
}
.details-form ::v-deep.ant-form-item-control-wrapper {
  display: inline-block;
  width: 50%;
}
</style>
