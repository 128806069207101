<template>
  <div class="annex-info bgStyle">
    <h3>附件</h3>
    <div class="NI-operate">
      <a-row>
        <a-col :span="12">
          <a-input-search
            placeholder="文档名称"
            v-model="filter"
            @search="loadDataAsync"
          />
        </a-col>
        <a-col :span="12" class="o-btn" v-if="isEdit">
          <a-button type="primary" @click="showModal">添加附件</a-button>
          <a-button type="primary" @click="showAppendix">关联附件</a-button>
        </a-col>
      </a-row>
      <a-table
        :columns="columns"
        :data-source="appendixData"
        bordered
        :pagination="pagination"
        class="appendix"
        :loading="loadingAttachment"
        :rowKey="(row) => row.id"
        @change="whenChangePage"
      >
        <div slot="version" slot-scope="text, record">
          <a-select
            :value="record.docVersion"
            :disabled="!isEdit"
            @change="whenChangeVersion($event, record)"
          >
            <a-select-option
              v-for="(item, index) in record.versions"
              :key="`${record.id}-${index}`"
              :value="item.docVersion"
            >
              {{ item.docVersion }}
            </a-select-option>
          </a-select>
        </div>
        <span
          slot="action"
          slot-scope="text, record"
          class="action-btn"
          style="font-size: larger"
          v-if="isEdit"
        >
          <a @click="whenViewRow(record)">
            <a-icon type="search" />
          </a>
          <a @click="whenEditRow(record)"> <a-icon type="edit" /> </a>
          <a
            :href="record.docUrl"
            target="_blank"
            @click="handleDownload(record)"
          >
            <a-icon type="download" />
          </a>
          <a @click="whenDeleteRow(record)"> <a-icon type="delete" /> </a>
        </span>
      </a-table>
    </div>
    <a-modal
      v-model="visibleNew"
      title="添加附件"
      :width="1200"
      :maskClosable="false"
      :destroyOnClose="true"
      :cancel-button-props="{ style: { display: 'none' } }"
      :ok-button-props="{ style: { display: 'none' } }"
      :confirmLoading="confirmLoading"
    >
      <add-doc ref="refAddDoc" @uploadOk="whenUploadOk"  />
    </a-modal>

    <a-modal
      v-model="visibleAppendixModal"
      title="关联附件"
      :maskClosable="false"
      :width="1000"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      @ok="whenSaveBind"
      :cancel-button-props="{ style: { display: 'none' } }"
    >
      <AppendixModal :existsData="figureDoc.existsData" ref="bind" />
    </a-modal>
  </div>
</template>

<script>
import AppendixModal from "../components/AppendixModal.vue";
import {
  getAttachmentList,
  bindAttachmentList,
  getFigureDocuemtIdList,
} from "@/api/material";
import {
  create,
  addDownLoadFigureDocOperateLog,
  getByCodeKeyPath,
} from "@/api/figureDocument.js";
import AddDoc from "@/views/FigureDocManage/components/AddDoc.vue";
import columns from "../attachments/detailColumns";
import uniqBy from "lodash/uniqBy";

export default {
  props: {
    itemId: { type: String, default: () => "" },
    isEdit: { type: Boolean, default: () => false },
  },
  
  components: {
    AppendixModal,
    AddDoc,
  },

  data: () => {
    return {
      visibleNew: false,
      visibleAppendixModal: false,
      appendixData: [],
      filter: undefined,
      columns,
      confirmLoading: false,
      loadingAttachment: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 1,
      },
      figureDoc: {
        existsData: [],
      },
    };
  },

  created() {
    this.loadDataAsync();
  },

  methods: {
    whenViewRow(row) {
      this.$router.push({
        name: "FigureDocManageDetailHis",
        params: {
          id: row.figureDocumentItemID,
          figureDocumentId: row.figureDocumentId,
          isFrom: "other-historyModal",
        },
      });
    },
    whenEditRow(row) {
      this.$router.push({
        name: "FigureDocManageDetail",
        params: {
          figureDocumentId: row.figureDocumentId,
          id:row.figureDocumentItemID,
          edit: true,
          isFrom: "other-list",
        },
      });
    },

    handleDownload(row) {
      getByCodeKeyPath("HS_TWD_CZLX_XZ").then((res) => {
        let params = {
          figureDocumentId: row.figureDocumentId,
          operateType: res.id,
        };
        addDownLoadFigureDocOperateLog(params);
        this.$message.success("下载成功!");
      });
    },

    whenDeleteRow(row) {
      this.loadingAttachment = true;
      this.$confirm({
        title: "确认解除与" + row.docName + "的绑定?",
        onOk: () => {
          bindAttachmentList({
            materialID: this.itemId,
            removeDocumentItems: [row.figureDocumentItemID],
          }).then(() => {
            this.loadDataAsync();
          });
        },
      });
    },

    whenChangeVersion(e, row) {
      const n = row.versions.find((x) => x.docVersion === e);
      const idx = this.appendixData.indexOf(row);
      if (idx >= 0) {
        n.versions = row.versions;
        n.index = row.index;
        this.appendixData.splice(idx, 1, n);
        bindAttachmentList({
          materialId: this.itemId,
          removeDocumentItems: [row.figureDocumentItemID],
          appendDocumentItems: [n.id],
        }).then(() => {
          this.loadDataAsync();
        });
      }
    },

    whenChangePage(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination ? pagination.current : pager.current;
      this.pagination = pager;
      this.loadDataAsync();
    },

    loadDataAsync() {
      if (!this.itemId) return;
      this.loadingAttachment = true;
      const params = {
        materialId: this.itemId,
        filter: this.filter,
        includeVersions: true,
        includeUserName: true,
        skipCount: this.skip,
        maxResultCount: this.pagination.pageSize,
      };
      getAttachmentList(params).then((res) => {
        this.appendixData = this.afterGetData(res.items);
        this.pagination.total = res.totalCount;
        this.loadingAttachment = false;
      });
    },

    // 添加附件
    showModal() {
      this.visibleNew = true;
    },

    // 关联附件
    showAppendix() {
      this.getFigureDocuemtIds();
      this.visibleAppendixModal = true;
    },

    //上传成功回调
    whenUploadOk(){
      let aliyunFileDtos = this.$refs.refAddDoc.aliyunOssFileDtos;
      let param={
        aliyunOssFileDtos:aliyunFileDtos
      };
      console.log("param:",param);
      
      create(param).then((res) => {
        console.log("create", res);
        this.visibleNew = false;
        this.$refs.refAddDoc.clearClick();
        const idList = res.map((x) => x.id);
        bindAttachmentList({
          materialID: this.itemId,
          appendDocumentItems: idList,
        }).then(() => {
            this.confirmLoading = false;
            this.visibleNew = false;
            this.loadDataAsync();
        })
        .catch(() => {
          this.confirmLoading = false;
          this.$refs.refAddDoc.loading=false;
        });
      })
      .catch(() => {
        this.confirmLoading = false;
      });
    },
 
    whenSaveBind() {
      const form = this.$refs.bind;
      const idList = form.right.selectedKeys;
      const toRemove = this.figureDoc.existsData.filter(
        (x) => idList.indexOf(x) < 0
      );

      this.confirmLoading = true;
      bindAttachmentList({
        materialID: this.itemId,
        appendDocumentItems: idList,
        removeDocumentItems: toRemove,
      }).then(() => {
        this.confirmLoading = false;
        this.visibleAppendixModal = false;
        this.loadDataAsync();
      });
    },

    afterGetData(data) {
      let myIndex = (this.pagination.current - 1) * this.pagination.pageSize;
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        element.index = ++myIndex;
      }
      return data;
    },

    getFigureDocuemtIds() {
      getFigureDocuemtIdList({ materialItemId: this.itemId })
        .then((res) => {
          this.figureDoc.existsData = res;
        })
        .catch();
    },
  },

  computed: {
    skip() {
      return (this.pagination.current - 1) * this.pagination.pageSize;
    },
  },

  watch: {
    itemId: {
      handler() {
        this.loadDataAsync();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.details h3 {
  margin-bottom: 20px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 10px;
}
.o-btn {
  text-align: right;
}
.o-btn .ant-btn:first-child {
  margin-right: 20px;
}

.action-btn a {
  margin-right: 10px;
}

.action-btn a:last-child {
  margin-right: 0;
}

.appendix {
  margin: 20px 0;
}
</style>
